
































































import {Component, Prop} from "vue-property-decorator";
import AbpBase from "@/lib/abpbase";
import SendEmail from "@/store/entities/jobcontext/sendemail";
import PropertyDescriptionDto from "@/store/entities/jobcontext/propertydescription";
import Wysiwyg from "@/components/Tinymce/tinymce.vue";
import PreviewEmail from "@/store/modules/notificationcontext/previewemail";

@Component({
  components: {
    Wysiwyg
  }
})
export default class SendEmailForm extends AbpBase {
  @Prop({default: null}) jobId: number | null;
  @Prop({type: Boolean, default: false}) value: boolean;
  private companies: any;
  private documents: any;
  private contacts: any;
  private spinShow: boolean = false;
  private previewVisible: boolean = false;
  private emailTemplates: any;
  private sendEmail: SendEmail = new SendEmail();
  private previewEmail: PreviewEmail = new PreviewEmail();
  private dynamicProperties: []

  data() {
    return {
      companies: [],
      documents: [],
      contacts: [],
      emailTemplates: [],
      dynamicProperties: []
    }
  };

  get properties() {
    return new Array<PropertyDescriptionDto>()
  }

  async preview() {
    this.previewVisible = true;
    let val = this.sendEmail.templateId;
    this.previewEmail = await this.$store.dispatch('job/previewEmail', {
      jobId: this.jobId,
      templateId: val,
      contactId: this.sendEmail.contactId,
      dynamicData: this.getDynamicProperties()
    });
  }

  async templateChanged(val) {
    if (val === undefined || val == null || val === 0) {
      this.previewVisible = false;
      this.previewEmail = new PreviewEmail();
      this.dynamicProperties = [];
    } else {
      (this.$refs.company as any).clearSingleSelect();
      this.companies = await this.$store.dispatch('job/getTemplateCompanies', {
        jobId: this.jobId,
        emailTemplateId: val
      });
      this.dynamicProperties = await this.$store.dispatch('emailTemplate/getDynamicProperties', val)
    }
  }

  async LoadContacts(query: string) {
    await this.LoadContactsForCompany(query, this.sendEmail.companyId);
  }

  async LoadContactsForCompany(query: string, companyId: number) {
    this.contacts = await this.$store.dispatch('contact/mailLookUp', {
      companyId: companyId,
      keyWord: query
    });
  }

  async isCompanyChange(val: number) {
    (this.$refs.contact as any).clearSingleSelect();
    if (val) {
      await this.LoadContactsForCompany('', val);
    }
  }

  getDynamicProperties() {
    if (this.dynamicProperties !== undefined && this.dynamicProperties !== null && this.dynamicProperties.length > 0) {
      let obj = {}
      console.log(this.dynamicProperties);
      this.dynamicProperties.forEach(x => obj[(x as any).name] = (x as any).value);
      return obj;
    }
    return null
  }

  async submit() {
    this.sendEmail.jobId = this.jobId;
    this.sendEmail.dynamicProperties = this.getDynamicProperties();
    console.log(this.sendEmail.dynamicProperties)
    this.spinShow = true;
    try {
      await this.$store.dispatch('job/email', this.sendEmail);
    } finally {
      this.spinShow = false;
    }
    this.previewVisible = false;
    (this.$refs.emailForm as any).resetFields();
    this.$emit('input', false);

  }

  cancel() {
    (this.$refs.emailForm as any).resetFields();
    this.$emit('input', false);
  }

  async companyClear() {
    (this.$refs.contact as any).clearSingleSelect();
    this.contacts = [];
  }

  async visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      this.sendEmail = new SendEmail();
      this.documents = await this.$store.dispatch('document/emailLookUp', this.jobId);
      this.emailTemplates = await this.$store.dispatch('emailTemplate/lookUp');
    }
  }
}
