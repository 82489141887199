var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{attrs:{"title":_vm.L('JobNode'),"value":_vm.value,"width":"40vw"},on:{"on-visible-change":_vm.visibleChange}},[_c('Table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"height":"300","no-data-text":_vm.L('NoDatas'),"border":"","data":_vm.list,"width":"40vw"},scopedSlots:_vm._u([{key:"order",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{key:row.nodeId,attrs:{"type":"text"},model:{value:(_vm.list[index].order),callback:function ($$v) {_vm.$set(_vm.list[index], "order", $$v)},expression:"list[index].order"}})]}},{key:"releaseDate",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('DatePicker',{key:row.nodeId,staticStyle:{"width":"100%"},attrs:{"editable":false,"type":"date","transfer":""},model:{value:(_vm.list[index].releaseDate),callback:function ($$v) {_vm.$set(_vm.list[index], "releaseDate", $$v)},expression:"list[index].releaseDate"}})]}},{key:"isDone",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticStyle:{"text-align":"center"}},[_c('Checkbox',{key:row.nodeId,model:{value:(_vm.list[index].isDone),callback:function ($$v) {_vm.$set(_vm.list[index], "isDone", $$v)},expression:"list[index].isDone"}})],1)]}},{key:"isSelected",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticStyle:{"text-align":"center"}},[_c('Checkbox',{key:row.nodeId,model:{value:(_vm.list[index].isSelected),callback:function ($$v) {_vm.$set(_vm.list[index], "isSelected", $$v)},expression:"list[index].isSelected"}})],1)]}}])}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.L('Cancel')))]),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.L('OK')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }