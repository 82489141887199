




































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import AbpBase from '../../../../lib/abpbase'
import UpdateJobNode from "@/store/entities/jobcontext/updatejobnodes";
import {_} from 'vue-underscore';

@Component
export default class JobNodeForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    private jobId: number;

    async save() {
        await this.$store.dispatch({
            type: 'jobNode/update',
            data: new UpdateJobNode(this.jobId, _.filter(this.jobNodeList.filter(x => x.isSelected), x => x.order))
        });
        this.$emit('save-success');
        this.$emit('input', false);
    }

    get list() {
        return _.chain(this.$store.state.jobNode.fullList).sortBy('order').sortBy(x => !x.isSelected).value();
    }

    get loading() {
        return this.$store.state.jobNode.loading;
    }

    cancel() {
        this.$emit('input', false);
    }

    private jobNodeList: any;

    columns = [{
        title: this.L('Order'),
        key: 'order',
        slot: 'order'
    }, {
        title: this.L('Name'),
        key: 'nodeName'
    }, {
        title: this.L('Date to be done'),
        key: 'releaseDate',
        slot: 'releaseDate'
    }, {
        title: this.L('Done'),
        key: 'isDone',
        slot: 'isDone'
    }, {
        title: this.L('Selected'),
        key: 'isSelected',
        slot: 'isSelected'
    }]

    async visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.jobId = this.$store.state.jobNode.jobId;
            this.jobNodeList = await this.$store.dispatch('jobNode/getAll', this.jobId);
        }
    }
}
