


















































































import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../../lib/abpbase";
import JobContainer from "../../../../store/entities/jobcontext/jobcontainer";
import JobContainersLookUpRequest from "@/store/entities/jobcontainerslookuprequest";
import Job from "@/store/entities/jobcontext/job";
import Container from "@/store/entities/containercontext/container";
import EditContainerType from "@/views/setting/containercontext/container/container-form.vue";
import ContainerLookUp from "@/store/entities/containercontext/containerlookup";
import LookUp from "@/store/entities/lookup";
import PackContainer from "@/store/entities/jobcontext/pack-container";
import PackContainerDto from "@/store/entities/jobcontext/pack-container";

@Component({
  components: {EditContainerType}
})
export default class JobContainerForm extends AbpBase {
  @Prop({default: new JobContainer()}) jobContainer: JobContainer;
  @Prop({default: new Job()}) job: Job;
  @Prop({default: new Array<LookUp>()}) packageTypes;
  private containers: any;
  private sizes: any;
  private createContainerModalShow: boolean = false;

  data() {
    return {
      containers: [],
      sizes: []
    }
  };

  isPackDisabled(){
    return !this.jobContainer.id || this.jobContainer.id <= 0;
  }

  removeContainer() {
    this.job.jobContainers = this.job.jobContainers.filter(x => x.containerId !== this.jobContainer.containerId);
  }

  parser(val) {
      return val.replace('.', '')
  }

  changed(val) {
    let container = this.containers.find(x => x.id === val);
    this.jobContainer.containerNumber = container.name;
    this.jobContainer.tareWeight = container.tareWeight;
    this.jobContainer.sizeId = container.sizeId;
  }

  async pack() {
    await this.$store.dispatch({
      type: 'job/packContainer',
      data: new PackContainerDto(this.job.id, this.jobContainer.id)
    });
    this.$emit('container-packed');
  }

  async create(val) {
    this.jobContainer.containerId = -1;
    let container = new Container();
    container.number = val;
    this.$store.commit('container/edit', container);
    this.createContainerModalShow = true;
  }

  putCreatedValue(val) {
    let container = new ContainerLookUp();
    container.id = val.id
    this.jobContainer.containerId = val.id;
    container.tareWeight = val.tareWeight;
    this.jobContainer.tareWeight = val.tareWeight;
    container.name = val.number
    this.jobContainer.containerNumber = val.number;
    container.sizeId = val.sizeId;
    this.jobContainer.sizeId = val.sizeId;

    this.containers.push(container);
  }

  async loadData() {
    this.containers = await this.$store.dispatch('job/getContainers', new JobContainersLookUpRequest('', this.job.jobContainers.filter(x => x.containerId).map(c => c.containerId), this.jobContainer.containerId));
  }

  async mounted() {
    this.sizes = await this.$store.dispatch('containersize/lookUp');
    if (this.jobContainer.containerId) {
      await this.loadData();
    }
  }
}
