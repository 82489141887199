








































import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../../lib/abpbase";
import JobDocumentRequestVm from "@/store/entities/jobcontext/jobdocumentrequest";
import Util from "@/lib/util";

@Component
export default class DocumentRequestForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  private request: JobDocumentRequestVm = new JobDocumentRequestVm();

  async previewDocument(id: number) {
    await this.$store.dispatch('jobDocumentRequest/previewDocument', id);
  }

  async applyDocument(id: number) {
    await this.$store.dispatch('jobDocumentRequest/applyDocument', id);
    this.$emit('save-success');
    await this.reloadForm();
  }

  async rejectDocument(id: number) {
    await this.$store.dispatch('jobDocumentRequest/rejectDocument', id);
    this.$emit('save-success');
    await this.reloadForm();
  }

  async reloadForm() {
    this.request = await this.$store.dispatch({
      type: 'jobDocumentRequest/get',
      data: this.request.id
    });
  }

  async applyRequestDocuments(id: number) {
    await this.$store.dispatch('jobDocumentRequest/applyAllDocuments', id);
    this.$emit('save-success');
    await this.reloadForm();
  }

  async visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      this.request = Util.extend(true, {}, this.$store.state.jobDocumentRequest.editDocumentRequest);
    }
  }
}
