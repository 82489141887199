
























import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../../lib/abpbase";
import JobImportTable from "./job-import-table.vue";
import Util from "@/lib/util";
import JobCompanyInstructionsComparisonVm from "@/store/entities/jobcontext/jobcompanyinstructionscomparisonvm";

@Component({
    components: {
        JobImportTable
    }
})
export default class JobImportForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({default: -1}) jobId: number;
    @Prop({default: 1}) instructionType: number;
    private data: Array<JobCompanyInstructionsComparisonVm> = new Array<JobCompanyInstructionsComparisonVm>();

    save() {
        this.$emit('save-success');
        this.$emit('input', false);
    }

    async updateData() {
        this.data = await this.$store.dispatch('job/getJobDefaultData', {
            jobId: this.jobId,
            instructionType: this.instructionType
        });
        this.$emit('data-updated');
    }

    async visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
            this.data = [];
        } else {
            await this.updateData();
        }
    }
}
