import Entity from '../entity'

export default class JobItem extends Entity<number> {
    jobId: number;
    packageTypeId: number;
    numberOfItems: number;
    length: number;
    width: number;
    height: number;
    weightPerItem: number;

    constructor(){
        super();
        this.numberOfItems = 1
    }
}
