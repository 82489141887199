var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Form',[_c('Row',[_c('Col',{attrs:{"offset":"18","span":"6"}},[_c('FormItem',[_c('div',{staticStyle:{"text-align":"right"}},[_c('Button',{attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.updateCurrentJobNode(_vm.currentNodeId)}}},[_vm._v(_vm._s(_vm.L('SetAsCurrentNode'))+" ")]),_c('Button',{staticStyle:{"margin-left":"10px"},attrs:{"disabled":_vm.disabled,"type":"primary"},on:{"click":_vm.nodeAdd}},[_vm._v(_vm._s(_vm.L('AddNode')))])],1)])],1)],1),_c('Row',{attrs:{"gutter":30}},[_c('Col',{attrs:{"span":"10"}},[_c('Table',{attrs:{"loading":_vm.loading,"columns":_vm.nodeColumns,"highlight-row":"","no-data-text":_vm.L('NoDatas'),"border":"","data":_vm.list},on:{"on-current-change":_vm.changeRow},scopedSlots:_vm._u([{key:"select",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticStyle:{"text-align":"center"}},[(row.nodeId === _vm.job.currentNodeId)?_c('Icon',{attrs:{"type":"md-checkmark"}}):_vm._e()],1)]}},{key:"releaseDate",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"disabled":"","type":"date","transfer":""},model:{value:(_vm.list[index].releaseDate),callback:function ($$v) {_vm.$set(_vm.list[index], "releaseDate", $$v)},expression:"list[index].releaseDate"}})]}},{key:"isDone",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Checkbox',{attrs:{"disabled":""},model:{value:(_vm.list[index].isDone),callback:function ($$v) {_vm.$set(_vm.list[index], "isDone", $$v)},expression:"list[index].isDone"}})]}}])})],1),_c('Col',{attrs:{"gutter":"1","span":"14"}},[_c('job-node-metadata-table',{attrs:{"placeHolder":"Documents","list":_vm.jobNodeDocuments}}),_c('job-node-metadata-table',{attrs:{"placeHolder":"Actions","list":_vm.jobNodeActions}}),_c('job-node-metadata-table',{attrs:{"placeHolder":"Metadata","list":_vm.jobNodeMetadata}})],1)],1)],1),_c('job-node-form',{on:{"save-success":function($event){return _vm.getNodes(_vm.jobId)}},model:{value:(_vm.jobNodeModalShow),callback:function ($$v) {_vm.jobNodeModalShow=$$v},expression:"jobNodeModalShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }