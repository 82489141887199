








































import AbpBase from "../../../../lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import Comp from 'vue-class-component'
import Job from "@/store/entities/jobcontext/job";
import Util from "@/lib/util";
import FormInput, {PositionType, SectionNumber} from "@/components/dynamic-forms/form-field";
import SectionGroupedJobForm from "@/views/setting/jobcontext/job/section-grouped-job-form.vue";
import NodeGroupedJobForm from "@/views/setting/jobcontext/job/node-grouped-job-form.vue";
import JobDocumentRequest from "@/views/setting/jobcontext/job/jobdocumentrequest.vue";

@Component({components: {SectionGroupedJobForm, NodeGroupedJobForm, JobDocumentRequest}})
export default class JobForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    private job: Job = new Job();
    private fieldsMetadata: Array<FormInput> = [];
    private jobId: number;


    async save() {
        this.job.jobContainers = this.job.jobContainers.filter(x => x.containerId);
        await this.$store.dispatch({
            type: 'job/update',
            data: this.job
        });
        await this.getJob(this.jobId);
        await this.getConsolidationJobs();
        (this.$refs.jobForm as any).isChanged = false;
        (this.$refs.jobForm as any).getJobJsonString();
    }

    async updateActions(){
        await (this.$refs.nodeForm as any).updateActions();
    }

    async jobLeave(next) {
        if ((this.$refs.jobForm as any).isChanged) {
            this.$Modal.confirm({
                title: this.L('Save changes before leave'),
                okText: this.L('Yes'),
                cancelText: this.L('No'),
                onOk: async () => {
                    this.job.jobContainers = this.job.jobContainers.filter(x => x.containerId);
                    await this.$store.dispatch({
                        type: 'job/update',
                        data: this.job
                    });
                    next();
                },
                onCancel: () => {
                    next();
                }
            })
        } else {
            next();
        }
    }

    beforeRouteUpdate(to, from, next) {
        this.jobLeave(next);
    }

    beforeRouteLeave(to, from, next) {
        this.jobLeave(next);
    }

    async cancel() {
        await this.$router.push({name: 'job'});
    }

    async jobTemplateUpdated() {
        await (this.$refs.nodeForm as any).getNodes(this.jobId);
    }

    async getMetadata(jobId: number) {
        await this.$store.dispatch('job/getMetadata', jobId);
        this.fieldsMetadata = this.$store.state.job.listFieldsMetadata;
    }

    async updateRequests() {
        await (this.$refs.requestsForm as any).getpage();
    }

    async getJob(jobId: number) {
        await this.$store.dispatch('job/get', jobId);
        this.job = Util.extend(true, {}, this.$store.state.job.editJob);
    }

    async getConsolidationJobs() {
        await this.$store.dispatch('job/getConsolidationJobs', this.job.consolidationNumber);
    }

    async created() {
        this.jobId = parseInt(this.$route.params["jobId"]);
    }

    async init() {
        await this.getJob(this.jobId);
        await this.getMetadata(this.jobId);
        await this.getConsolidationJobs();
    }

    async mounted() {
        await this.init();
    }

    async documentsUpdated() {
        await (this.$refs.jobForm as any).getDocuments(this.jobId);
    }

    async nodeUpdated() {
        await (this.$refs.jobForm as any).getDocuments(this.jobId);
        await (this.$refs.jobForm as any).getActions(this.jobId);
    }
}
