import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import CompanyKeyWordLookUpRequest from "@/store/entities/ordercontext/company-keyword-lookup-request";

export default class JobCompanyKeyWordLookUpRequest extends CompanyKeyWordLookUpRequest {
    jobId: number;

    constructor(keyword: string, id: number | null, code: string, jobId: number) {
        super(keyword, id, code);
        this.jobId = jobId;
    }
}
