

















import {Component, Prop} from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import DocumentRequestForm from "@/views/setting/jobcontext/job/jobdocumentrequest-form.vue";

class PagedJobDocumentRequest extends PageRequest {
    jobId: number;
}

@Component({
    components: {DocumentRequestForm}
})
export default class JobDocumentRequest extends AbpBase {
    @Prop({default: 0}) jobId: number;
    private viewModalShow: boolean = false;

    pagerequest: PagedJobDocumentRequest = new PagedJobDocumentRequest();

    documentsUpdated() {
        this.$emit('documents-updated');
    }

    get list() {
        return this.$store.state.jobDocumentRequest.list;
    };


    get loading() {
        return this.$store.state.jobDocumentRequest.loading;
    }


    async pageChange(page: number) {
        this.$store.commit('jobDocumentRequest/setCurrentPage', page);
        await this.getpage();
    }

    async search() {
        await this.pageChange(1);
    }

    async pagesizeChange(pagesize: number) {
        this.$store.commit('jobDocumentRequest/setPageSize', pagesize);
        await this.getpage();
    }

    async getpage() {
        this.pagerequest.maxResultCount = this.pageSize;
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
        this.pagerequest.jobId = this.jobId;

        await this.$store.dispatch({
            type: 'jobDocumentRequest/getAll',
            data: this.pagerequest
        })
    }

    get pageSize() {
        return this.$store.state.jobDocumentRequest.pageSize;
    }

    get totalCount() {
        return this.$store.state.jobDocumentRequest.totalCount;
    }

    get currentPage() {
        return this.$store.state.jobDocumentRequest.currentPage;
    }

    preview() {
        this.viewModalShow = true;
    }

    columns = [{
        title: this.L('Creation date'),
        key: 'creationDate',
    }, {
        title: this.L('Documents'),
        key: 'documents',
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 100,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small'
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: async () => {
                            this.$store.commit('jobDocumentRequest/edit', await this.$store.dispatch({
                                type: 'jobDocumentRequest/get',
                                data: params.row.id
                            }));
                            this.preview();
                        }
                    }
                }, this.L('View'))])
        }
    }]

    async mounted() {
        await this.getpage();
    }
}
