import FieldSubject from "@/components/dynamic-forms/fieldsubject";

export enum FieldTypes {
    Number,
    Text,
    Date,
    DateTime,
    Select,
    CheckBox
}

export enum LookUpType {
    Company,
    CompanyAddress,
    MasterPrepaidCollect,
    ShippingMethod,
    ContainerSize,
    ContainerType,
    ISOCode,
    PackageType,
    VehicleType,
    TradeTransportLocation,
    Incoterm,
    Vessel,
    AirlineCode,
    Container,
    BillStatus,
    Currency
}

export enum PositionType {
    Left,
    Center,
    Right
}

export enum SectionNumber {
    First,
    Second,
    Third,
    Fourth,
    Fifth,
    Sixth,
    Seventh,
    Eighth,
    Ninth
}

export default class FormInput {
    label: string;
    fieldType: FieldTypes;
    lookUpType: LookUpType | null;
    property: string;
    number: number | null;
    color: string;
    positionType: PositionType | null;
    section: SectionNumber | null;
    dependsOn: Array<string> | null;
    presents: boolean;
    nodeId: number | null;
    nodeName: string;
    isConsolidation: boolean;
    subjects: Array<FieldSubject>;
    categoryCode: string;
}
