








import AbpBase from "../../../../lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import JobNodeMetadataVm from "@/store/entities/jobcontext/jobnodemetadatavm";

@Component
export default class JobNodeMetadataTable extends AbpBase {
    @Prop({default: new Array<JobNodeMetadataVm>()}) list: Array<JobNodeMetadataVm>
    @Prop({default: ''}) placeHolder: string;

    columns = [{
        title: this.L('Name'),
        key: 'name',
    }, {
        title: ' ',
        key: 'hasValue',
        width: 50,
        render: (h: any, params: any) => {
            return h('div', [
                h('Icon', {
                    props: {
                        type: params.row.hasValue ? 'md-checkmark' : 'ios-alert'
                    }
                })
            ])
        }
    }]
}
