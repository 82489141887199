export default class JobInstructionApplyDto {
    jobId: number;
    companyInstructionId: number;
    property: string;

    constructor(jobId: number, companyInstructionId: number, property: string) {
        this.companyInstructionId = companyInstructionId;
        this.jobId = jobId;
        this.property = property;
    }
}
