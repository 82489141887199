import Entity from "@/store/entities/entity";

export default class JobContainer extends Entity<number> {
    jobId: number;
    containerId: number;
    numberOfPackages: number;
    hazardousClasses: string;
    packagesDescription: string;
    netWeight: number;
    volume: number;
    containerNumber: string;
    sealNumber: string;
    tareWeight: number;
    sizeId: number;
    size: string;
    packageTypeId: number;
    goodsDescription: string
    hsCode: string;
    marksAndNumbers: string;
}
