























import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Template from "../../../../store/entities/jobcontext/template";

@Component
export default class CreateTemplateForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    private template: Template = new Template();

    async save() {
        await this.$store.dispatch({
            type: 'job/createTemplate',
            data: this.template
        });
        (this.$refs.nodeForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
    }

    cancel() {
        (this.$refs.nodeForm as any).resetFields();
        this.$emit('input', false);
    }

    async visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.template = new Template();
            this.template.jobId = Util.extend(true, {}, this.$store.state.job.editJob).id;
        }
    }

}
