






































































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Container from '../../../../store/entities/containercontext/container'
import ContainerTypeLookUpRequest from "@/store/entities/containercontext/lookuprequest/containertypelookuprequest";
import IsoCodeLookUpRequest from "@/store/entities/containercontext/lookuprequest/isocodelookuprequest";


@Component
export default class EditContainerType extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;
    container: Container = new Container();
    private imagePresents: boolean = false;

    containerTypes: any;
    isoCodes: any;
    containerSizes: any;

    data() {
        return {
            containerTypes: [],
            isoCodes: [],
            containerSizes: []
        }
    };

    handleUpload(file) {
        this.container.uploadedPhoto = file;
        this.container.photo = URL.createObjectURL(file);
        this.imagePresents = true;
    }

    async loadContainerTypes() {
        this.containerTypes = await this.$store.dispatch('containertype/lookUp');
    }

    async loadIsoCodes() {
        this.isoCodes = await this.$store.dispatch('isocode/lookUp');
    }

    async mounted() {
        this.containerSizes = await this.$store.dispatch('containersize/lookUp');
    }

    created() {
    }

    save() {
        (this.$refs.containerForm as any).validate(async (valid: boolean) => {
            if (valid) {
                let response = await this.$store.dispatch({
                    type: this.toEdit ? 'container/update' : 'container/create',
                    data: this.prepareFormData()
                });
                (this.$refs.containerForm as any).resetFields();
                this.$emit('save-success', response);
                this.$emit('input', false);
            }
        })
    }

    prepareFormData() {
        let formData = new FormData();
        if (this.container.tareWeight) {
            formData.append('tareWeight', this.container.tareWeight.toString());
        }
        if (this.container.id) {
            formData.append('id', this.container.id.toString());
        }
        if (this.container.number) {
            formData.append('number', this.container.number);
        }
        if (this.container.typeId) {
            formData.append('typeId', this.container.typeId.toString());
        }
        if (this.container.isVerified !== undefined) {
            formData.append('isVerified', this.container.isVerified.toString());
        }
        if (this.container.isoCodeId) {
            formData.append('isoCodeId', this.container.isoCodeId.toString());
        }
        if (this.container.sizeId) {
            formData.append('sizeId', this.container.sizeId.toString());
        }
        if (this.container.uploadedPhoto) {
            formData.append('photo', this.container.uploadedPhoto);
        } else {
            if (this.container.photo) {
                formData.append('photo', this.b64toBlob(this.container.photo));
            }
        }
        return formData;
    }

    b64toBlob(dataURI) {
        let byteString = atob(dataURI.split(',')[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: dataURI.match(/[^:]\w+\/[\w-+\d.]+(?=[;,])/)[0]});
    }

    cancel() {
        (this.$refs.containerForm as any).resetFields();
        this.$emit('canceled');
        this.$emit('input', false);
    }

    async visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.container = Util.extend(true, {}, this.$store.state.container.editContainer);
            if (this.container.photo) {
                this.imagePresents = true;
            }
            await this.loadContainerTypes();
            await this.loadIsoCodes();
        }
    }

    checkIsoCodeInput(rule, value, callback) {
        if (value == null) {
            return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Container type'))));
        }
        return callback();
    }

    containerTypeRule = {
        number: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
    }
}
